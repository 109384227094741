<template>
  <div class="content_box">
    <div class="content_box_title">账号设置</div>
    <!-- 头像个人信息 -->
    <div class="content_box_user">
      <div class="content_box_user_info">
        <img
          :src="userInfo.icon? userInfo.icon : require(`@/assets/img/defaultavatar.png`)"
          alt
        />
        <div class="content_box_user_info_right">
          <div class="content_box_user_info_right_name">
            <span class="right_name">{{ userInfo.nickName }}</span>
            <span
              class="right_ico"
              v-if="userInfo.sex == 1 || userInfo.sex == 2"
              ><i
                class="iconfont"
                :class="
                  userInfo.sex == 1
                    ? 'iconweibiaoti-1-34'
                    : 'iconweibiaoti-1-33'
                "
                :style="userInfo.sex == 1 ? '' : 'color:#FF29C7;'"
              ></i
              >{{ sexTip }}</span
            >
          </div>
          <div class="content_box_user_info_right_number">
            <span v-if="userInfo.mobile" class="right_number_phone">手机:</span
            >{{ userInfo.mobile }}
            <span v-if="userInfo.loginName" class="right_number_id">学号:</span
            >{{ userInfo.loginName }}
          </div>
        </div>
      </div>
      <div class="content_box_user_edit">
        <button class="content_box_user_edit_button" @click="editInfo">
          <i class="iconfont">&#xe62b;</i>
          编辑
        </button>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="content_box_division">
      <div class="content_box_division_title">手机绑定</div>
      <div class="content_box_division_line"></div>
    </div>
    <!-- 手机绑定 -->
    <div class="content_box_binding" v-if="userInfo.mobile">
      <i class="iconfont">&#xe612;</i>
      <div class="content_box_binding_phone">
        <div>手机已绑定（{{ userInfo.mobile }}）</div>
        <span>绑定后不可修改</span>
      </div>
    </div>
    <div class="content_box_binding" v-else>
      <i class="iconfont" style="color: #333333">&#xe612;</i>
      <div class="content_box_binding_phone">
        <div>手机未绑定</div>
        <span>建议立即绑定手机</span>
      </div>
      <button class="content_box_edit_button" @click="bindingMobile">
        绑定手机
      </button>
    </div>
    <!-- 分割线 -->
    <div class="content_box_division">
      <div class="content_box_division_title">密码安全</div>
      <div class="content_box_division_line"></div>
    </div>
    <!-- 密码安全 -->
    <div class="content_box_binding" v-if="userInfo.password == 1">
      <i class="iconfont">&#xe608;</i>
      <div class="content_box_binding_phone">
        <div>修改密码</div>
        <span>建议定期修改密码</span>
      </div>
      <button class="content_box_edit_button" @click="editPwd">修改密码</button>
    </div>
    <div class="content_box_binding" v-else>
      <i class="iconfont" style="color: #333333">&#xe608;</i>
      <div class="content_box_binding_phone">
        <div>设置</div>
        <span>建议立即设置密码</span>
      </div>
      <button class="content_box_edit_button" @click="setUpPwd">
        设置密码
      </button>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/personal";
export default {
  data() {
    return {
      userInfo: {},
      sexTip: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    //获取个人信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          switch (res.data.sex) {
            case 1:
              this.sexTip = "男";
              break;
            case 2:
              this.sexTip = "女";
              break;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //修改个人信息
    editInfo() {
      this.$emit("editInfo");
    },
    //绑定手机
    bindingMobile() {
      this.$emit("bindingMobile");
    },
    //修改密码
    editPwd() {
      this.$emit("editPwd");
    },
    //设置密码
    setUpPwd() {
      this.$emit("setUpPwd");
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  height: 748px;
}

.content_box_title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  height: 60px;
  text-align: left;
  padding-left: 32px;
  border-bottom: 1px solid #eeeeee;
}
// 头像个人信息
.content_box_user {
  text-align: left;
  padding: 26px 30px 0 32px;
  width: 100%;
  height: 157px;
  display: inline-block;
  & > .content_box_user_info {
    margin-top: 7px;
    float: left;
    & > img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      float: left;
      display: inline-block;
      vertical-align: top;
    }
    & > .content_box_user_info_right {
      margin-left: 16px;
      margin-top: 12px;
      float: left;
      display: inline-block;
      vertical-align: top;
      & > .content_box_user_info_right_name {
        & > .right_name {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          line-height: 20px;
        }
        & > .right_ico {
          margin-left: 19px;
          margin-right: 5px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          & > .iconfont {
            color: #0d79ff;
          }
        }
      }
      & > .content_box_user_info_right_number {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        & > .right_number_phone {
          color: #999999;
        }
        & > .right_number_id {
          color: #999999;
          margin-left: 31px;
        }
      }
    }
  }
  & > .content_box_user_edit {
    float: right;
    & > .content_box_user_edit_button {
      width: 82px;
      text-align: center;
      height: 33px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 17px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
    & > .content_box_user_edit_button:hover {
      background: #0d79ff;
      color: #fff;
    }
  }
}
//分割线
.content_box_division {
  margin: 0 32px 0 30px;
  width: 100%;
  height: 17px;
  & > .content_box_division_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
    margin-right: 14px;
    min-width: 75px;
    float: left;
  }
  & > .content_box_division_line {
    width: 817px;
    height: 1px;
    background: #eeeeee;
    margin-top: 9px;
    float: left;
  }
}
//手机号绑定
.content_box_binding {
  height: 116px;
  width: 100%;
  padding: 33px 30px 0 32px;
  & > .iconfont {
    font-size: 27px;
    color: #0d79ff;
    float: left;
  }
  & > .content_box_binding_phone {
    margin-left: 13px;
    text-align: left;
    float: left;
    & > div {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      margin-bottom: 6px;
    }
    & > span {
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      line-height: 12px;
    }
  }
}
//按钮
.content_box_edit_button {
  width: 82px;
  text-align: center;
  height: 31px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 28px;
  margin-left: 30px;
  float: left;
}
.content_box_edit_button:hover {
  background: #0d79ff;
  color: #fff;
}
</style>